<template>
  <div></div>
</template>
<script>
import store from "@/store";
import { listRecursion } from "@/utils/convert";
export default {
  data() {
    return {
      code: "",
    };
  },
  mounted() {
    this.code = this.$route.query.code;
    this.state = this.$route.query.state;
    if (this.code && this.state) {
      this.login();
    } else if (sessionStorage.getItem("isLogin")) {
      let userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
      this.getMenuList(userinfo);
    }
  },
  methods: {
    login() {
      this.$axios.get(
        "oauth2",
        { code: this.code, state: this.state },
        (res) => {
          let status = res.status;
          if (status.toString().substring(0, 1) == 2) {
            let isLogin = true;
            sessionStorage.setItem("isLogin", JSON.stringify(isLogin));
            sessionStorage.setItem("userinfo", JSON.stringify(res.data));
            this.getMenuList(res.data);
          }
        }
      );
    },
    getMenuList(userinfo) {
      this.$axios.get("menusList", { user_id: userinfo.id }, (con) => {
        let conStatus = con.status;
        if (conStatus.toString().substring(0, 1) == 2) {
          let data = this.arraySplit(con.data);
          let menus = listRecursion(data[0]);
          let menu = this.getRouter(menus[0]);
          store.setGlobalState({
            isLogin: true,
            userinfo: userinfo,
            menuActive: menu.id,
            menuList: menus,
            navMenu: [menu],
            buttonList: data[1],
          });
          sessionStorage.setItem("menuActive", JSON.stringify(menu.id));
          sessionStorage.setItem("menuList", JSON.stringify(menus));
          sessionStorage.setItem("navMenu", JSON.stringify([menu]));
          sessionStorage.setItem("buttonList", JSON.stringify(data[1]));
          this.$router.push(menu.path);
        }
      });
    },
    arraySplit(list) {
      let data = [];
      data[0] = [];
      data[1] = [];
      for (let i = 0; i < list.length; i++) {
        if (list[i].type == "MENU") {
          data[0].push(list[i]);
        } else {
          data[1].push(list[i]);
        }
      }
      return data;
    },
    getRouter(menu) {
      if (menu.children && menu.children.length > 0) {
        return this.getRouter(menu.children[0]);
      } else {
        return menu;
      }
    },
  },
};
</script>
